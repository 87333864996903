import Vue from 'vue';
import Vuex from 'vuex';
import PortalVue from 'portal-vue';
import cloneDeep from 'lodash/cloneDeep';

// import {HelpHeroVuex} from '../helphero'
import {MixpanelVuex} from '../mixpanel';
import VuexTracking from '../tracking/vuex-tracking';
import VuexUndoRedo from './vuex-undo-redo';
import VuexAutosave from './vuex-autosave';
import VuexActionLogger from './vuex-action-logger';
import user, {initial as initialUser} from './modules/user';
import controls, {initial as initialControls} from './modules/controls';
import editor, {initial as initialEditor} from './modules/editor';
import elements, {initial as initialElements} from './modules/elements';
import nesting, {initial as initialSlots} from './modules/nesting';
import funnel, {initial as initialFunnel} from './modules/funnel';
import pages, {initial as initialPages} from './modules/pages';
import popups, {initial as initialPopups} from './modules/popups';
import undoredo, {initial as initialUndoRedo} from './modules/undoredo';
import project from './modules/project';
import workspace from './modules/workspace';

Vue.use(Vuex);
Vue.use(PortalVue);

export const initial = {
  initialState: {
    user: cloneDeep(initialUser),
    controls: cloneDeep(initialControls),
    nesting: cloneDeep(initialSlots),
    editor: cloneDeep(initialEditor),
    elements: cloneDeep(initialElements),
    funnel: cloneDeep(initialFunnel),
    pages: cloneDeep(initialPages),
    popups: cloneDeep(initialPopups),
    undoredo: cloneDeep(initialUndoRedo),
  },
};

export const RESET_STATE = 'RESET_STATE';
export const SET_INITIAL_STATE = 'SET_INITIAL_STATE';

const store = new Vuex.Store({
  plugins: [
    // HelpHeroVuex,
    VuexUndoRedo,
    MixpanelVuex,
    VuexTracking,
    VuexActionLogger,
    VuexAutosave,
  ],

  state: cloneDeep(initial),

  modules: {
    user,
    controls,
    editor,
    elements,
    funnel,
    pages,
    popups,
    nesting,
    undoredo,
    project,
    workspace,
  },

  mutations: {
    [RESET_STATE](state) {
      const exclude = ['user'];
      Object.keys(initial.initialState).forEach(function (key) {
        if (!exclude.includes(key)) {
          state[key] = cloneDeep(initial.initialState[key]);
        }
      });
    },

    [SET_INITIAL_STATE](state, newInitialState) {
      state.initialState = newInitialState;
    },
  },
});

export default store;
