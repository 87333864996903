<template>
  <UserProvider v-slot="{userRole, userController}">
    <DialogProvider>
      <NoticeBar></NoticeBar>
      <LowPageviewBar></LowPageviewBar>
      <Redirects></Redirects>
      <Dialog :open="domainDialogVisible" @close="handleDialogHeaderClose">
        <DialogHeader class="flex justify-between items-center">
          Domains to upgrade
          <Button variant="outlined" @click="handleDialogHeaderClose">
            <Icon name="times" slot="start-icon" /> Close
          </Button>
        </DialogHeader>

        <DialogContent>
          <Alert type="info" class="mb-4 mt-4 alert-domains">
            For performance and cost optimisation reasons, we're switching to a
            new CDN provider. Please update your DNS records.
          </Alert>
          <div class="projects">
            <template v-if="upgradeDomains.length">
              <div
                class="
                  domain
                  cursor-pointer
                  border
                  rounded
                  dull-light
                  mb-4
                  p-4
                  flex
                  items-center
                  justify-between
                "
                v-for="(domain, index) in upgradeDomains"
                :key="index"
                @click="handleDomainClick(domain)"
              >
                <span class="text-dull-dark">{{ domain.url }}</span>
                <span class="text-danger">Invalid DNS</span>
              </div>
            </template>
            <p v-else>No domains found. You are done!</p>
          </div>
        </DialogContent>
      </Dialog>

      <div id="app">
        <DocumentDragListner>
          <template v-if="userController">
            <AdminFlightControl :userController="userController" />
          </template>
          <router-view />
          <portal-target name="app" multiple />
          <DialogFullscreen class="overflow-auto" :open="isMigrationRunning">
            <div class="flex items-center justify-center h-screen">
              <div class="text-center">
                <h1 class="text-primary text-2xl">We are updating Converdy</h1>
                <p class="mt-4 text-secondary" v-if="timeRemaining">
                  Your work has been saved. The app will be back online in:
                  {{ timeRemaining }}
                </p>
              </div>
            </div>
          </DialogFullscreen>
          <Toasts />
          <AdminMobileBlock v-if="userRole" />
          <img class="w-0 h-0" src="./assets/logo-animated.gif" />
        </DocumentDragListner>
      </div>
    </DialogProvider>
    <!-- <div class="version">v{{ version }}</div> -->
  </UserProvider>
</template>

<script>
/* Todo probably move this to a seperate provider component */
import * as actionCreators from './store/action-creators';
import {elementPropertyChanged} from './store/action-creators';
import {funnelSaved} from './store';
import Vue from 'vue';
import DocumentDragListner from './DocumentDragListner';
import AdminFlightControl from './components/AdminFlightControl';
import AdminMobileBlock from './components/AdminMobileBlock';
import Toasts from '@converdy/components/Toasts';
import moment from 'moment';
import db from '@converdy/firebase/db';
import DialogProvider from './DialogProvider';
import UserProvider from './UserProvider';
import Redirects from './Redirects';
import NoticeBar from './NoticeBar';
import LowPageviewBar from './LowPageviewBar';
import {setup as setupMessageBox} from '@converdy/atoms/MessageBox/index';
import auth from '@converdy/firebase/auth';
import {setCookie, deleteCookie, getCookie} from '@converdy/utils/cookie';
import axios from 'axios';
import {sendMessage, onMessage, types} from '@converdy/utils/postmessage';
import debounce from 'lodash/debounce';

axios.interceptors.response.use(undefined, function (error) {
  if (error.response.status == 401)
    document.dispatchEvent(
      new CustomEvent('converdySuperToast', {
        detail: {
          content: 'You are not authorised to perform this action !',
          title: 'Unauthorized !',
        },
      }),
    );

  return Promise.reject(error);
});

setupMessageBox(Vue);

/* Proxy $getLocalized, used in SSR components also see website/localized.js */
Vue.prototype.$getLocalized = (key, defaultValue) => defaultValue;

/* Import global styles */
import '@converdy/styles/base.scss';
const minutesDellay = 5;
export default {
  data() {
    return {
      upgradeDomains: [],
      domainDialogVisible: false,
      activeSessions: 0,
      unsubscribe: null,
      isMigrationRunning: false,
      timeRemaining: '',
      interval: null,
      removePostMessageListener: false,
      version: '',
      clickListener: false,
    };
  },
  components: {
    LowPageviewBar,
    NoticeBar,
    Redirects,
    UserProvider,
    DialogProvider,
    DocumentDragListner,
    AdminFlightControl,
    AdminMobileBlock,
    Toasts,
  },

  watch: {
    // Save when migration starts running and we're in the editor
    isMigrationRunning(value) {
      if (value) {
        if (this.$router.currentRoute.path.includes('editor')) {
          this.$store.dispatch(funnelSaved());
        }
        return true;
      }
    },
  },
  created() {
    this.version = window.__CONVERDY__.version;

    this.removePostMessageListener = onMessage(({type, payload}) => {
      if (types.DISPATCH_TO_STORE == type) {
        // Todo maybe some checks?
        if (payload.type === 'ELEMENT_PROPERTY_CHANGED') {
          this.$store.dispatch({
            type: 'ELEMENT_PROPERTY_INPUT',
            payload: payload.payload,
          });
          this.debouncedChange(payload.payload);
        } else {
          this.$store.dispatch(payload);
        }
      }
    });

    this.clickListener = () => {
      sendMessage({
        type: types.TEXT_CLICKED_OUTSIDE,
      });
    };

    window.addEventListener('click', this.clickListener);
    let firstTime = false;
    let onboardingOnRepeat = true; // Show onboarding if nog finished, regardless of he signed in before
    auth.onAuthStateChanged(async (user) => {
      const adminDomain =
        window.__ADMIN__.indexOf('localhost') == -1
          ? window.__ADMIN__.split('//')[1].split('admin.')[1]
          : '';

      if (user) {
        firstTime = user.metadata.creationTime == user.metadata.lastSignInTime;

        setCookie(
          'converdy_auth_token',
          await user.getIdToken(true),
          365 * 24 * 60 * 60,
          adminDomain,
        );

        const getMe = async (tries) => {
          try {
            const data = (await axios.get('/users/me')).data;
            return data;
          } catch (err) {
            if (tries == 0) return false;
            await new Promise((a) => setTimeout(a, 2000));
            return await getMe(--tries);
          }
        };
        const localUser = await getMe(10);
        if (window.__CONVERDY__.invite) {
          axios
            .post('/users/accept-invite', {
              invite: window.__CONVERDY__.invite,
            })
            .then(({data}) => {
              delete window.__CONVERDY__.invite;
              setTimeout(() => {
                this.$store.dispatch('project/setProjectById', {
                  id: data.projectId,
                });
                this.$router.push({
                  name: 'funnels',
                  params: {
                    workspace_id: 'shared',
                    projectId: data.projectId,
                    confirmed: true,
                  },
                });
              }, 3000);
              this.dispatchToast({
                type: 'success',
                title: data.msg,
                dismissible: true,
                dismissIn: 10000,
              });
            })
            .catch((err) => {
              console.log(err.response);
              this.dispatchToast({
                type: 'error',
                title: err.response.data.msg,
                dismissible: true,
                dismissIn: 10000,
              });
            });
        }

        if (
          (firstTime || onboardingOnRepeat) &&
          window.location.pathname != '/admin'
        ) {
          // just logged in or created account
          this.$store.dispatch({type: 'user/loadCurrentUser'});

          if (getCookie('converdy_redirect_to')) {
            this.$router.push({name: getCookie('converdy_redirect_to')});
            deleteCookie('converdy_redirect_to', adminDomain);
          } else {
            // For regular users
            if (
              !localUser.print_user_id &&
              localUser.onboard_data &&
              !localUser.onboard_data.is_onboard
            ) {
              this.$router.push({name: 'onboard'});
            } else if (this.$router.currentRoute.name === 'login') {
              this.$router.push({name: 'workspaces'});
            } else if (this.$router.currentRoute.name) {
              // do nothing, load on current page
            } else {
              this.$router.push({name: 'workspaces'});
            }
          }
        } else {
          // was allready authentificated
          setCookie(
            'converdy_auth_token',
            await auth.currentUser.getIdToken(true),
            365 * 24 * 60 * 60,
            adminDomain,
          );

          this.$store.dispatch({type: 'user/loadCurrentUser'});
          if (
            window.location.pathname == '/login' ||
            window.location.pathname == '/register' ||
            window.location.pathname == '/' ||
            window.location.pathname.startsWith('/reset')
          ) {
            this.$router.push({name: 'workspaces'});
          } else {
            if (getCookie('converdy_redirect_to')) {
              this.$router.push({name: getCookie('converdy_redirect_to')});
              deleteCookie('converdy_redirect_to', adminDomain);
            }
          }
        }
      } else {
        deleteCookie('converdy_auth_token', adminDomain);
        if (
          window.location.pathname != '/login' &&
          window.location.pathname != '/register' &&
          window.location.pathname != '/register-print' &&
          !window.location.pathname.startsWith('/reset') &&
          !window.location.pathname.startsWith('/oauth/zapier')
        ) {
          this.$router.push({name: 'login'});
        }
      }
      firstTime = true;
    });
    auth.onIdTokenChanged(async (user) => {
      const adminDomain =
        window.__ADMIN__.indexOf('localhost') == -1
          ? window.__ADMIN__.split('//')[1].split('admin.')[1]
          : '';
      if (user)
        setCookie(
          'converdy_auth_token',
          await auth.currentUser.getIdToken(true),
          365 * 24 * 60 * 60,
          adminDomain,
        );
    });

    this.unsubscribe = db
      .collection('migrations')
      .where(
        'startDate',
        '>',
        parseInt(
          moment()
            .add(minutesDellay * -1, 'minutes')
            .utc()
            .format('x'),
        ),
      )
      .onSnapshot((querySnapshot) => {
        const migrations = [];
        querySnapshot.forEach((migration) => {
          migrations.push(migration.data());
        });

        const isRunning = migrations.filter((m) => m.endDate == 0).length != 0;
        const hasFreshMigrations =
          migrations.filter(
            (m) =>
              m.endDate >
              parseInt(
                moment()
                  .add(minutesDellay * -1, 'minutes')
                  .utc()
                  .format('x'),
              ),
          ).length != 0;

        this.isMigrationRunning = isRunning || hasFreshMigrations;
        if (this.interval) clearInterval(this.interval);
        this.timeRemaining = '';
        const setTime = () => {
          const a = migrations.sort((a, b) => b.endDate - a.endDate)[0].endDate;
          const b = parseInt(moment().utc().format('x'));
          const diff = minutesDellay * 60 - Math.round((b - a) / 1000);
          const duration = moment.duration(diff, 'seconds');
          this.timeRemaining = `${duration.minutes()} minutes ${duration.seconds()} seconds`;
          if (diff <= 0) window.location.reload();
        };
        if (hasFreshMigrations && !isRunning) {
          setTime();
          this.interval = setInterval(() => {
            setTime();
          }, 1000);
        }
      });

    this.getDomains();
  },
  beforeDestroy() {
    if (this.removePostMessageListener) this.removePostMessageListener();
    if (this.unsubscribe) this.unsubscribe();
    if (this.interval) clearInterval(this.interval);
    window.removeEventListener('click', this.clickListener);
  },

  methods: {
    debouncedChange: debounce(function (payload) {
      this.$store.dispatch(elementPropertyChanged(payload));
    }, 350),
    handleDialogHeaderClose() {
      this.domainDialogVisible = false;
    },
    handleDomainClick(domain) {
      this.domainDialogVisible = false;
      this.$router.push({
        name: 'projectSettingsDomainsManage',
        params: {
          projectId: domain.project_id,
          domain_id: domain._id,
        },
      });
    },

    async getDomains() {
      try {
        const me = await axios.get('/users/me/domains');
        if (me.status === 200) {
          this.upgradeDomains = me.data;
        }
      } catch (err) {
        console.error(err);
      }
    },
  },
};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500;600;800&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
}

body {
  @apply h-screen
    bg-dull-light
    antialiased
    font-display
    font-medium;
}

div.firebase-emulator-warning {
  display: none !important;
  visibility: hidden;
}

/* We probably could use a base tailwind file where we define e.g. the loader class as a component */
.loader {
  position: relative;
  overflow: hidden;
  --color: theme('colors.primary.default');
  --colorCircle: hsl(211, 97%, 58%, 20%);
  --size: 35px;
  --ratio: 0.12;
  pointer-events: none;
}

.loader:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  width: var(--size);
  height: var(--size);
  text-indent: -9999em;
  border-top: calc(var(--ratio) * var(--size)) solid var(--colorCircle);
  border-right: calc(var(--ratio) * var(--size)) solid var(--colorCircle);
  border-bottom: calc(var(--ratio) * var(--size)) solid var(--colorCircle);
  border-left: calc(var(--ratio) * var(--size)) solid var(--color);
  -webkit-animation: Loader 1.1s infinite linear;
  animation: Loader 1.1s infinite linear;
  z-index: 10000;
}

.loader:after {
  content: '';
  width: 1000%;
  height: 1000%;
  position: absolute;
  background: white;
  z-index: 1000;
  opacity: 0.5;
}

.loader-transparent.loader:after {
  background: transparent;
  opacity: 1;
}

@keyframes Loader {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

/* Disables Message */
.disabled-message {
  pointer-events: none;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  z-index: 10;
  height: 40px;
  background: black;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.7;
}

.tippy-popper {
  cursor: default !important;
  // box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);

  .tippy-tooltip {
    border-radius: 8px !important;
    box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1),
      0 4px 6px -4px rgb(0 0 0 / 0.1);
    font-weight: 400 !important;
  }

  .tippy-tooltip {
    padding: 12px !important;
  }

  // Theme light
  .tippy-tooltip.light-theme {
    color: rgba(0, 27, 66, var(--text-opacity)) !important;
    .tippy-backdrop {
      background: white !important;
    }
  }

  a {
    cursor: pointer;
    @apply text-primary font-medium;
  }

  // background: white !important;
  cursor: pointer;
}
.version {
  position: fixed;
  z-index: 100;
  pointer-events: none;
  bottom: 5px;
  left: 5px;
  @apply text-dull-dark text-xs;
}

.alert-domains {
  max-width: 600px;
}
</style>
