import Vue from 'vue';
import {sync} from 'vuex-router-sync';
import ReactiveProvide from 'vue-reactive-provide';
import PortalVue from 'portal-vue';
import VueRouter from 'vue-router';
import VueMoment from 'vue-moment';
import Converdy from '@converdy/atoms/plugin';
import BaseGeneral from '@converdy/c-components/Base/Base.general';
import BaseImage from '@converdy/c-components/BaseImage/BaseImage';
import BaseRating from '@converdy/c-components/BaseRating/BaseRating';
import VueTippy, {TippyComponent} from 'vue-tippy';

import '@fortawesome/fontawesome-pro/css/fontawesome.css';
import '@fortawesome/fontawesome-pro/css/brands.css';
import '@fortawesome/fontawesome-pro/css/regular.css';

import http from './http';
import router from './router';
import store from './store';
import filters from './filters';
import loadingCountMixin from './mixins/loading-count';
import toast from './mixins/toast';

Vue.component('BaseRating', BaseRating);
Vue.component('BaseImage', BaseImage);
Vue.component('BaseGeneral', BaseGeneral);
Vue.use(ReactiveProvide);
Vue.use(Converdy);
Vue.use(filters);

sync(store, router); // done. Returns an unsync callback fn

Vue.mixin(loadingCountMixin);
Vue.mixin(toast);

Vue.use(VueMoment);
Vue.use(PortalVue);
Vue.use(VueRouter);

Vue.use(VueTippy, {
  directive: 'tippy', // => v-tippy
  flipDuration: 0,
  animationFill: false,
  duration: [350, 250],
  theme: 'light',
  interactive: true,
});

Vue.component('tippy', TippyComponent);

Vue.config.productionTip = false;
