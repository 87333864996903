import overwriteDeep from '@converdy/utils/overwrite-deep'

/*
 * this will only make sense if we're using a consistent action-format
 * e.g. fsa (https://github.com/redux-utilities/flux-standard-action)
 */

let order = 0

export default function withUndoRedo (
  action,
  { checkpoint, stack } = {}
) {
  const undoredo = {
    order: order++,
    checkpoint,
    stack
  }

  return overwriteDeep(action, { meta: { undoredo } })
}
