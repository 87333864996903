<template></template>

<script>
export default {
  inject: ['currentUser'],

  watch: {
    // Check on route change and change currentUser
    $route(to, from) {
      this.check();
    },
    currentUser: {
      deep: true,
      handler() {
        this.check();
      },
    },
  },
  methods: {
    check() {
      let routeName = this.$router.currentRoute.name;
      let {
        isLtd,
        hasActiveCbSubscription,
        userTrialStatus,
        cbDueInvoicesCount,
        outOfPageviews,
      } = this.currentUser;

      // If user is not on a plan route and they are not LTD users
      if (['plans', 'profile/billing'].includes(routeName) && !isLtd) {
        // ## TRAIL EXPIRED, REDIRECT TO PLANS
        if (userTrialStatus == 'expired' && !hasActiveCbSubscription) {
          window.location.href = '/plans';
          return;
        }
      }

      // ## DUE INVOICES, REDIRECT TO BILLING
      if (
        cbDueInvoicesCount > 0 &&
        this.$router.currentRoute.name !== 'profile/billing'
      ) {
        window.location.href = '/profile/billing';
        return;
      }

      // ALL USERS
      // ## OUT OF PAGEVIEWS
      if (
        outOfPageviews &&
        !['profile/limits', 'plans', 'profile/billing'].includes(routeName)
      ) {
        window.location.href = '/profile/limits';
        return;
      }
    },
  },
};
</script>

<style scoped></style>
