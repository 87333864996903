<template>
  <div
    v-if="
      !isExcluded &&
      !currentUser.isLtd &&
      !currentUser.hasActiveCbSubscription &&
      currentUser.userTrialStatus == 'active'
    "
    class="
      notice-bar
      bg-secondary-dark
      text-center text-primary
      flex
      items-center
      justify-center
    "
  >
    <!-- <span class="mr-4 text-dull-dark" -->
    <span class="mr-4 text-white"
      >You have {{ currentUser.userTrialDaysLeft }} days left in your
      trial</span
    >
    <Button
      variant="basic"
      color="primary"
      size="small"
      @click="$router.push('/plans')"
    >
      Buy Converdy
    </Button>
  </div>
</template>

<script>
export default {
  inject: ['currentUser'],
  computed: {
    // Exclude from editor and plans path
    isExcluded() {
      return (
        this.$store.state.route &&
        (this.$store.state.route.path.includes('editor') ||
          this.$store.state.route.path.includes('plans') ||
          this.$store.state.route.path.includes('register'))
      );
    },
  },
};
</script>

<style scoped>
.notice-bar {
  height: 60px;
}
</style>
