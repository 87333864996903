<template>
  <div class="app-flight-control flex flex-col justify-between">
    <div class="flex-grow">
      <Typography fontColor="white" variant="headingFour">
        Controller: {{ userController.first_name }}
        {{ userController.last_name }}
      </Typography>
    </div>
    <div>
      <Button color="primary" @click.native="exit">
        <Icon name="times-square" slot="start-icon" />
        Take Back
      </Button>
    </div>
  </div>
</template>

<script>
import auth from '@converdy/firebase/auth';
import {deleteCookie, setCookie} from '@converdy/utils/cookie';

export default {
  inject: ['currentUser'],
  props: ['userController'],

  methods: {
    async exit() {
      const adminDomain =
        window.__ADMIN__.indexOf('localhost') == -1
          ? window.__ADMIN__.split('//')[1].split('admin.')[1]
          : '';

      try {
        const token = this.currentUser.userController.token;
        deleteCookie('admin_take_back_auth_token', adminDomain);
        setCookie(
          'converdy_redirect_to',
          'admin',
          365 * 24 * 60 * 60,
          adminDomain,
        );
        auth.signInWithCustomToken(token);
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style>
.app-flight-control {
  max-width: 400px;
  height: 140px;
  width: 100%;
  @apply fixed
      p-4
      left-0
      bottom-0
      ml-4
      mb-4
      bg-secondary
      rounded;
}
</style>
