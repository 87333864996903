import Vue from 'vue';

const TrackingBus = new Vue();

TrackingBus.$on('track', (dataToDestructure) => {
  // console.log(dataToDestructure, 'dataToDestructure')
  const [eventName, data] = dataToDestructure;
  dataLayer.push({
    event: `APP_${eventName}`,
    ...data,
  });
});

// Add to prototype, available through Vue.$TrackingBus
Vue.prototype.$TrackingBus = TrackingBus;

// Export
export default TrackingBus;
