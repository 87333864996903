export default {
	methods: {
		get_loading_count(resource) {
      let count = window.localStorage.getItem(`last_known_count_${resource}`)

      count = (count > 10) ? 10 : count || 0

			return Number(count)
    },

		set_loading_count(resource, resource_count) {
			window.localStorage.setItem(`last_known_count_${resource}`, resource_count)
		}
	}
}
