import {
  CONTROL_UI_CHANGED,
  DEVICE_TYPE_CHANGED,
  EDITOR_LOADED,
  EDITOR_MOUNTED,
  EDITOR_UNMOUNTED,
  ELEMENT_CLICKED,
  ELEMENT_DELETED,
  ELEMENT_INSERTED,
  ELEMENT_LOADED,
  ELEMENT_POSITION_CHANGED,
  ELEMENT_PROPERTY_CHANGED,
  ELEMENT_PROPERTY_INPUT,
  ELEMENT_SORT_ENDED,
  FUNNEL_LOADED,
  FUNNEL_SAVED,
  UNDOREDO_CHECKPOINT_RESTORED,
  UNDOREDO_UNDO,
  UNSELECT_ELEMENTS,
  DIALOG,
  ELEMENTS_LOADED,
  ELEMENT_CLONED,
  ELEMENT_CREATED,
  ELEMENT_MOVED_DOWN,
  ELEMENT_MOVED_UP,
  ELEMENT_PROPERTY_RESET,
  ELEMENT_SAVED,
  ELEMENT_SORT_STARTED,
  FUNNEL_CUSTOM_DOMAIN_UPDATED,
  FUNNEL_DEPLOYED,
  FUNNEL_PROPERTY_CHANGED,
  FUNNEL_PROPERTY_INPUT,
  THEME_PROPERTY_CHANGED,
  THEME_PROPERTY_INPUT,
  FUNNEL_SETTINGS_UPDATED,
  HIGHLIGHTED_NODES,
  PAGES_LOADED,
  PAGE_CLONED,
  PAGE_CREATED,
  PAGE_DELETED,
  PAGE_INSERTED,
  PAGE_SORTED,
  PAGE_LOADED,
  PAGE_RESOURCE_LOADED,
  PAGE_SETTINGS_UPDATED,
  PAGE_PROPERTY_CHANGED,
  PAGE_PROPERTY_INPUT,
  PAGE_SAVED,
  POPUP_RESOURCE_INSERTED,
  POPUPS_LOADED,
  POPUP_SORTED,
  POPUP_RESOURCE_LOADED,
  POPUP_SETTINGS_UPDATED,
  POPUP_DELETED,
  UNDOREDO_ACTIVE_STACK_CHANGED,
  UNDOREDO_REDO,
  USER_LOADED,
  ACTIVE_DOCUMENT_CHANGED,
  SPLIT_TEST_CREATED,
  SPLIT_TEST_UPDATED,
  SPLIT_TEST_STOPPED,
  SPLIT_TEST_STARTED,
  PAGE_CONTENT_SWAPPED,
  SIDEBAR_VIEW_CHANGED,
} from './action-types';

import withUndoRedo from './with-undo-redo';

export function controlUiChanged(payload) {
  return withUndoRedo({type: CONTROL_UI_CHANGED, payload});
}

export function dialog(payload) {
  return {type: DIALOG, payload};
}

export function elementClicked(payload) {
  return withUndoRedo({type: ELEMENT_CLICKED, payload});
}

export function deviceTypeChanged(payload) {
  return withUndoRedo({type: DEVICE_TYPE_CHANGED, payload});
}

export function editorLoaded(funnelId) {
  return {type: EDITOR_LOADED, funnelId};
}

export function editorMounted(projectId, funnelId) {
  return {type: EDITOR_MOUNTED, payload: {projectId, funnelId}};
}

export function editorUnmounted(projectId, funnelId) {
  return {type: EDITOR_UNMOUNTED, payload: {projectId, funnelId}};
}

export function elementInserted(payload) {
  return {type: ELEMENT_INSERTED, payload};
}

export function elementsLoaded(payload) {
  return withUndoRedo({type: ELEMENTS_LOADED, payload});
}

export function elementCloned(payload) {
  return {type: ELEMENT_CLONED, payload};
}

export function elementCreated(payload) {
  return withUndoRedo({type: ELEMENT_CREATED, payload}, {checkpoint: true});
}

export function elementDeleted(payload) {
  return withUndoRedo({type: ELEMENT_DELETED, payload}, {checkpoint: true});
}

export function elementLoaded(payload) {
  return withUndoRedo({type: ELEMENT_LOADED, payload});
}

export function elementPositionChanged(payload) {
  return withUndoRedo(
    {type: ELEMENT_POSITION_CHANGED, payload},
    {checkpoint: true},
  );
}

export function elementMovedUp(payload) {
  return withUndoRedo({type: ELEMENT_MOVED_UP, payload}, {checkpoint: true});
}

export function elementMovedDown(payload) {
  return withUndoRedo({type: ELEMENT_MOVED_DOWN, payload}, {checkpoint: true});
}

export function elementPropertyChanged(payload) {
  return withUndoRedo(
    {type: ELEMENT_PROPERTY_CHANGED, payload},
    {checkpoint: true},
  );
}

export function elementPropertyInput(payload) {
  return {type: ELEMENT_PROPERTY_INPUT, payload};
}

export function elementPropertyReset(payload) {
  return withUndoRedo(
    {type: ELEMENT_PROPERTY_RESET, payload},
    {checkpoint: true},
  );
}

export function elementSaved(payload) {
  return {type: ELEMENT_SAVED, payload};
}

export function elementSortEnded(payload) {
  return withUndoRedo({type: ELEMENT_SORT_ENDED, payload});
}

export function elementSortStarted(payload) {
  return withUndoRedo({type: ELEMENT_SORT_STARTED, payload});
}

export function funnelLoaded(payload) {
  return withUndoRedo({type: FUNNEL_LOADED, payload});
}

export function funnelSaved(payload) {
  return {type: FUNNEL_SAVED, payload};
}

export function funnelCustomDomainUpdated(payload) {
  return {type: FUNNEL_CUSTOM_DOMAIN_UPDATED, payload};
}

export function funnelDeployed(payload) {
  return {type: FUNNEL_DEPLOYED, payload};
}

export function funnelPropertyChanged(payload) {
  return withUndoRedo(
    {type: FUNNEL_PROPERTY_CHANGED, payload},
    {stack: 'funnelStyling', checkpoint: true},
  );
}

export function funnelPropertyInput(payload) {
  return {type: FUNNEL_PROPERTY_INPUT, payload};
}

export function themePropertyChanged(
  payload,
  undoRedoSettings = {stack: 'theme', checkpoint: true},
) {
  return withUndoRedo(
    {type: THEME_PROPERTY_CHANGED, payload},
    undoRedoSettings,
  );
}

export function themePropertyInput(payload) {
  return {type: THEME_PROPERTY_INPUT, payload};
}

export function pagePropertyChanged(payload) {
  return withUndoRedo({type: PAGE_PROPERTY_CHANGED, payload});
}

export function pagePropertyInput(payload) {
  return {type: PAGE_PROPERTY_INPUT, payload};
}

export function highlightedNodes(payload) {
  return {type: HIGHLIGHTED_NODES, payload};
}

export function pageCreated(payload) {
  // return withUndoRedo({type: PAGE_CREATED, payload}, {checkpoint: true});
  return {type: PAGE_CREATED, payload};
}

export function pageCloned(payload) {
  return {type: PAGE_CLONED, payload};
}

export function pageSaved(payload) {
  return {type: PAGE_SAVED, payload};
}

export function pageDeleted(payload) {
  // return withUndoRedo({type: PAGE_DELETED, payload}, {checkpoint: true});
  return {type: PAGE_DELETED, payload};
}

export function pageInserted(payload) {
  return {type: PAGE_INSERTED, payload};
}

export function pageAdded(payload) {
  return withUndoRedo({type: PAGE_LOADED, payload}, {checkpoint: true});
}

export function pageLoaded(payload) {
  return withUndoRedo({type: PAGE_LOADED, payload});
}

export function pageSorted(payload) {
  return {type: PAGE_SORTED, payload};
}

export function pagesLoaded(payload) {
  return {type: PAGES_LOADED, payload};
}

export function pageResourceLoaded(payload) {
  return {type: PAGE_RESOURCE_LOADED, payload};
}

export function pageSettingsUpdated(payload) {
  /*
   * TODO: withUndoRedo?
   */
  return {type: PAGE_SETTINGS_UPDATED, payload};
}

export function popupResourceLoaded(payload) {
  return {type: POPUP_RESOURCE_LOADED, payload};
}

export function popupResourceInserted(payload) {
  return {type: POPUP_RESOURCE_INSERTED, payload};
}

export function popupSorted(payload) {
  return {type: POPUP_SORTED, payload};
}

export function popupsLoaded(payload) {
  return {type: POPUPS_LOADED, payload};
}

export function popupSettingsUpdated(payload) {
  return {type: POPUP_SETTINGS_UPDATED, payload};
}

export function popupDeleted(payload) {
  return {type: POPUP_DELETED, payload};
}

export function undoRedoRedo(payload = {}) {
  return {type: UNDOREDO_REDO, payload};
}

export function undoRedoUndo(payload = {}) {
  return {type: UNDOREDO_UNDO, payload};
}

export function undoRedoCheckpointRestored(payload) {
  return {type: UNDOREDO_CHECKPOINT_RESTORED, payload};
}

export function undoRedoActiveStackChanged(payload) {
  return {type: UNDOREDO_ACTIVE_STACK_CHANGED, payload};
}

export function unselectElements(payload) {
  return {type: UNSELECT_ELEMENTS, payload};
}

export function userLoaded(payload) {
  return {type: USER_LOADED, payload};
}

export function activeDocumentChanged(payload) {
  return {type: ACTIVE_DOCUMENT_CHANGED, payload};
}

export function splitTestCreated(payload) {
  return {type: SPLIT_TEST_CREATED, payload};
}

export function splitTestUpdated(payload) {
  return {type: SPLIT_TEST_UPDATED, payload};
}

export function splitTestStopped(payload) {
  return {type: SPLIT_TEST_STOPPED, payload};
}

export function splitTestStarted(payload) {
  return {type: SPLIT_TEST_STARTED, payload};
}

export function pageContentSwapped(payload) {
  return {type: PAGE_CONTENT_SWAPPED, payload};
}

export function headerClicked(payload) {
  return {type: HEADER_CLICKED, payload};
}

export function sidebarViewChanged(payload) {
  return {type: SIDEBAR_VIEW_CHANGED, payload};
}
