<template>
  <div
    v-if="
      typeof currentUser.pageviewsLeft == 'number' &&
      currentUser.pageviewsLeft < 20000
    "
    class="
      notice-bar
      bg-red-600
      text-center text-primary
      flex
      items-center
      justify-center
    "
  >
    <!-- <span class="mr-4 text-dull-dark" -->
    <span class="mr-4 text-white"
      >You have
      {{ currentUser.pageviewsLeft.toLocaleString('en-US') }} pageviews left for
      the current period. Please upgrade</span
    >
    <Button
      variant="basic"
      color="danger"
      size="small"
      @click="$router.push({name: 'profile/limits'})"
    >
      Buy views
    </Button>
  </div>
</template>

<script>
export default {
  inject: ['currentUser'],
  created() {},
  computed: {},
};
</script>

<style scoped>
.notice-bar {
  height: 60px;
}
</style>
