<template>
  <div class="mobile-block">
    <div class="text-center">
      <h3 class="text-2xl mb-2 text-secondary">Welcome to Converdy 🎉</h3>
      <h2 class="text-l mb-2 text-secondary">Please switch to desktop</h2>
      <p class="text-gray-500">While Converdy allows you to build stunning sites for both desktop and mobile the editor currently only works on desktop!</p>
    </div>
  </div>
</template>
 
<script>
export default {

}
</script>

<style scoped>

.mobile-block {
  position: fixed;
  width: 100%;
  height: 100%;
  background: #fff;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;
  visibility: hidden;
  opacity: 0;
}

@media screen and (max-width: 769px) {
  .mobile-block {
    visibility: visible;
    opacity: 1;
  }
}

</style> 
