<template>
  <div>
    <slot />
  </div>
</template>

<script>

export default {

  data() {
    return {
      plansVisible: false
    };
  },

  reactiveProvide: {
    name: 'dialogs',
    include: [
      'plansVisible',
    ],
  }

};
</script>
