<template>
  <div :class="{'is-dragging': dragging}">
    <slot/>
   </div>  
</template>

<script>

/*
   To prevent interaction with the iframe while dragging
 */ 

export default {
  data: function() {
    return {
      dragging: false,
      mousedown: false,
      listnerMouseDown:() => { 
        this.dragging = false
        this.mousedown = true
      },
      listnerMouseMove:() => { 
        this.dragging = this.mousedown ? true : false
      },
      listnerMouseUp:() => { 
        this.mousedown = false
        this.dragging = false
      }
    }
  },
  mounted() {
    this.addListners()
  },
  beforeDestroy() {
    this.removeListner()
  },
  methods: {

    addListners() {
      document.addEventListener('mousedown', this.listnerMouseDown); 
      document.addEventListener('mousemove', this.listnerMouseMove); 
      document.addEventListener('mouseup', this.listnerMouseUp); 
    },

    removeListner() {
      document.removeEventListener('mousedown', this.listnerMouseDown); 
      document.removeEventListener('mousemove', this.listnerMouseMove); 
      document.removeEventListener('mouseup', this.listnerMouseUp); 
    }

  }

}
</script>

<style>

.is-dragging iframe {
  pointer-events: none;
}

</style>