export default function (value, currency) {
  if (!value) {
    value = 0;
  }
  // return value.toLocaleString(undefined, { style: 'currency', currency })

  return value.toLocaleString('en', {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
}
