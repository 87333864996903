import Vue from 'vue';

import router from './router';
import store from './store';
import App from './App.vue';
import {createPopper} from '@popperjs/core';
import './tracking';

import createConverdySwipeable, {
  create,
  destroy,
} from '@converdy/c-utils/swipeable-grid/swipeable-grid';

window.clientPlugins = {};

window.clientPlugins.swipeableGrid = {
  create,
  destroy,
};



window.__CONVERDY__ = {
  api_url: process.env.VUE_APP_API_BASE_URL,
  version:  process.env.VUE_APP_VERSION,
  breakpoint: 'desktop',
};

if(window.location.search.indexOf("invite=") != -1)
  window.__CONVERDY__.invite = window.location.search.split("invite=")[1].split("&")[0]
  
window.__CONVERDY_ENV__ = 'admin';

window.__CONVERDY_ADMIN__ = {
  Vue,
  store,
  router,
  createPopper,
};
import './setup';

new Vue({
  provide: {
    env: 'admin',
  },
  router,
  store: store,
  render: (h) => h(App),
}).$mount('#app');
